import React from 'react';
import Layout from './index';
import messages from '../../constants/messages/uk';

const LayoutUk =  (props) => (
	<Layout
		{...props}
		i18nMessages={messages}
	/>
);

export default LayoutUk;