module.exports = {
	'selectLanguage': 'uk',
	'up': 'Вгору',
	'toSimulatorButton': 'Почати практику',
	'englishBlockContent': {
		'title': 'Англійська, Англійська та ще раз Англійська!',
		'text1': 'Якщо ти хочеш працювати над крутими проектами і добре заробляти, думаю, не буде перебільшенням сказати, що англійська так само важлива, як і хард скіли.',
		'text2': 'На початку кар\'єри я мав проблеми з англійською. По-перше, був страх говорити з носіями мови, а іноді я просто завалював співбесіду, коли доходило до інгліша. Я і зараз продовжую займатися з носієм мови, оскільки це найефективніший спосіб вивчення. Я користуюся сервісом PrePly, там багато нейтів викладачів з якими можно займатись у зручний час.',
		'text3': 'Використовуючи моє посилання ти отримаєш знижку 70% на перший урок.',
		'button': 'Перейти',
	},
	'author': 'Автор',
	'anotherQuestions': 'Інші питання',
	'questionsNote': 'Якщо у тебе є питання чи пропозиції, напиши мені:',
};